import changeTheState from "./loginLogout";
// import changeLoginState from "./loginstatred";
import selectStatements from "./statements";
import { combineReducers } from "redux";
import costingnoReducer from "./costingnoReducer";
import productionnoReducer from "./productionnoReducer";

const rootReducers = combineReducers({
  changeTheState,
  // changeLoginState,
  selectStatements,
  costingnoReducer,
  productionnoReducer,
});

export default rootReducers;
