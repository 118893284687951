const initialState = "";

const productionnoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_PRODUCTIONNO":
      return action.payload;
    default:
      return state;
  }
};

export default productionnoReducer;
