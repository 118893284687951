import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../CSS/ProductionModule.css";
import axios from "axios";
import Select from "react-select";
import { useSelector } from "react-redux";
import AllNav from "../Components/Navbar/AllNav";
import LoadingScreen from "./LoadingScreen";
import { productionno } from "../Redux/Action/productionno";
import { useDispatch } from "react-redux";

const ProductionModule = () => {
  const state = useSelector((state) => state.changeTheState.user);
  const productionReport = useSelector((state) => state?.productionnoReducer);
  const dispatch = useDispatch();
  const username = state.emailId;
  const [showTable, setShowTable] = useState(false);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  const [productionNo, setProductionNo] = useState("");
  const [listofproductionno, setlistofproductionno] = useState([]);
  const [selproductionNo, setselProductionNo] = useState("");
  const [product, setProduct] = useState([]);
  const [selItemCode, setselItemCode] = useState("");
  const [chargescost, setChargesCost] = useState([]);
  const [selitemname, setselItemName] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [tableData, setTableData] = useState([]);
  const [selectedChargesCost, setSelectedChargesCost] = useState("");
  const [otherChargesCost, setOtherChargesCost] = useState("");
  const [deriveditem, setDerivedItem] = useState([]);
  const [selitemcode1, setselItemCode1] = useState("");
  const [selitemname1, setselItemName1] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [rawsizebag, setRawsizebag] = useState("");
  const [derivedsizebag, setDerivedSizeBag] = useState("");
  const [costprice, setCostprice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantity1, setQuantity1] = useState("");
  const [perc, setPerc] = useState("");
  const [costafterperc, setCostAfterPerc] = useState("");
  const [sizeofBagafterperc, setSizeofBagAfterPerc] = useState("");
  const [costafterextractperc, setCostafterextractPerc] = useState("");
  const [sizeofBagafterextractperc, setSizeofBagafterExtractPerc] =
    useState("");
  const [extractperc, setExtractPerc] = useState("");
  const [addedValues, setAddedValues] = useState([]);
  const [costperbag, setCostPerBag] = useState("");
  const [otherChargesData, setOtherChargesData] = useState([]);
  const [numberOfBags, setNumberOfBags] = useState();
  const [totalOtherCharges, settotalOtherCharges] = useState(0);
  const [totalExtractedKg, settotalExtractedKg] = useState(0);
  const [totalExtractedKghistupd, settotalExtractedKghistupd] = useState(0);
  const [histupdaterawkgs, sethistupdaterawkgs] = useState(0);
  const [totalDerivedCost, settotalDerivedCost] = useState(0);
  const [totalDerivedCosthistupd, settotalDerivedCosthistupd] = useState(0);
  const [totalSizeOfBag, setTotalSizeOfBag] = useState(0);
  const [totalCostPerBag, setTotalCostPerBag] = useState(0);
  const [totalCostPerBaghistupd, setTotalCostPerBaghistupd] = useState(0);
  const [totalCostPerBag1, setTotalCostPerBag1] = useState(0);
  let newSizeOfBag = 0;
  let newTotalCostPerBag = 0;
  let newExtractedKg = 0;
  let newDerivedCost = 0;
  let newOtherCharges = 0;
  const [historicRaw, setHistoricRaw] = useState([]);
  const [historicDerived, sethistoricDerived] = useState([]);
  const [historicOtherCharges, sethistoricOtherCharges] = useState([]);
  let totalSizeOfBagg = 0;
  let totalCostPerBagg = 0;
  let totalSizeOfBaggs = 0;
  let totalCostPerBaggs = 0;
  let totalderr = 0;
  let totalderrkgs = 0;
  let totalderrs = 0;
  let totalderrkgss = 0;
  let othercharges = 0;
  let charge = 0;
  let qtybags = 0;
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRowData1, setSelectedRowData1] = useState(null);
  const [selectedRowData2, setSelectedRowData2] = useState(null);
  const [index, setIndex] = useState(null);
  const [updatedRawMaterials, setupdatedRawMaterials] = useState([]);
  const [indexd, setIndexD] = useState(null);
  const [updatedDerived, setUpdatedDerived] = useState([]);
  const [rawflag, setRawflag] = useState(0);
  const [derivedflag, setDerivedflag] = useState(0);
  const [markup, setmarkup] = useState("");
  const [hist, setHist] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [millnolist, setMillNoList] = useState([]);
  const [selmillno, setSelmillno] = useState("");

  useEffect(() => {
    getListOfProductionNumber();
    getProductionNo();
    getproduct();
    getotherchargescost();
    getMillNoDetails();
    getDerivedItems();
  }, []);

  useEffect(() => {
    addedValues.forEach((value) => {
      newSizeOfBag += Number(value.sizeOfBag);
    });

    addedValues.forEach((value) => {
      newTotalCostPerBag += parseFloat(value.totalCost);
    });

    tableData.forEach((value) => {
      newExtractedKg += Number(value.extractedKgs);
    });

    tableData.forEach((value) => {
      newDerivedCost += value.extractioncostOtherCharges
        ? parseFloat(value.extractioncostOtherCharges)
        : parseFloat(value.costAfterExtraction);
    });

    otherChargesData.forEach((value) => {
      newOtherCharges += parseFloat(value.totalCharges);
    });

    setTotalCostPerBag(newTotalCostPerBag);
    settotalDerivedCost(newDerivedCost);
    settotalOtherCharges(newOtherCharges);
    if (newSizeOfBag > 90) {
      RemoveRawMaterials();
      alert("you are exceeding the limit");
    } else {
      setTotalSizeOfBag(newSizeOfBag);
    }
    if (newExtractedKg > newSizeOfBag) {
      RemoveDeriveditems();
      alert("you are exceeding the limit");
    } else {
      settotalExtractedKg(newExtractedKg);
    }
  }, [addedValues, tableData, otherChargesData]);

  updatedRawMaterials.forEach((item) => {
    totalSizeOfBaggs += parseFloat(item.KBBASEDONPERC);
    totalCostPerBaggs += parseFloat(item.RAWMATERIALCOST);
  });

  updatedDerived.forEach((item) => {
    totalderrs += parseFloat(item.DERIVEDTOTALKG);
    totalderrkgss += parseFloat(item.DERIVEDKG);
  });

  historicRaw.forEach((item) => {
    totalSizeOfBagg += parseFloat(item.KBBASEDONPERC);
    totalCostPerBagg += parseFloat(item.RAWMATERIALCOST);
  });

  historicDerived.forEach((item) => {
    totalderr += parseFloat(item.DERIVEDTOTALKG);
    totalderrkgs += parseFloat(item.DERIVEDKG);
  });

  historicOtherCharges.forEach((item) => {
    othercharges += parseFloat(item.CHARGAMOUNTPERBAG);
    charge += parseFloat(item.OTHERCHARGEAMOUNT);
    qtybags += parseFloat(item.FORQTYBAGS);
  });

  useEffect(() => {
    let AutoCalculate = [];
    if (updatedDerived.length > 0) {
      AutoCalculate = [...updatedDerived];
    } else {
      AutoCalculate = [...historicDerived];
    }
    for (let i = 0; i < AutoCalculate.length; i++) {
      const numericValue1 = parseFloat(
        AutoCalculate[i].STANDARDKGBAG.replace(/[^0-9.]/g, "")
      );
      let cost = 0;
      let size = 0;
      cost = totalCostPerBaggs;
      size = totalSizeOfBaggs;
      const cost1 = parseFloat(
        cost * (Number(AutoCalculate[i].DERIVEDITEMPERC) / 100)
      ).toFixed(2);
      const size1 = parseFloat(
        size * (Number(AutoCalculate[i].DERIVEDITEMPERC) / 100)
      ).toFixed(2);
      const costperBag1 = parseFloat(
        numericValue1 * (Number(cost1) / Number(size1))
      ).toFixed(2);
      const totalOtherCharges = othercharges / qtybags;
      const OtherCharge =
        totalOtherCharges * (Number(AutoCalculate[i].DERIVEDITEMPERC) / 100);
      AutoCalculate[i].DERIVEDITEMRATE = Number(cost1);
      AutoCalculate[i].DERIVEDKG = size1;
      AutoCalculate[i].COSTOFSTANDARDBAG = Number(costperBag1);
      AutoCalculate[i].DERIVEDTOTALKG = Number(cost1) + OtherCharge;
      AutoCalculate[i].COSTPERSTANDARDKGDERIVED =
        Number(costperBag1) + OtherCharge;
      AutoCalculate[i].DERIVEDSELLINGPRICE =
        Number(costperBag1) +
        OtherCharge +
        Number(
          (Number(costperBag1) + OtherCharge) *
            (Number(AutoCalculate[i].MARKUPPERC) / 100)
        );
    }
    setUpdatedDerived(AutoCalculate);
  }, [totalCostPerBaggs, totalSizeOfBaggs]);

  const handleRowClick = (rowData, index) => {
    setRawflag(1);
    setIndex(index);
    setselItemName(rowData.RAWMATERIALDESCRIPTION);
    setselItemCode(rowData.RAWMTERIALCODE);
    handleItemName(rowData.RAWMATERIALDESCRIPTION);
    setQuantity(rowData.RAWMATERIALQTY);
    setPerc(rowData.RAWMATRERIALPERCENTAGE);
    setSizeofBagAfterPerc(rowData.KBBASEDONPERC);
    setCostAfterPerc(rowData.RAWMATERIALRATE);
  };

  const handleRowClickk = (rowData, index) => {
    setRawflag(1);
    setIndex(index);
    setselItemName(rowData.itemName);
    setselItemCode(rowData.itemCode);
    handleItemName(rowData.itemName);
    setQuantity(rowData.itemQty);
    setPerc(rowData.percentage);
    setSizeofBagAfterPerc(rowData.sizeOfBag);
    setCostAfterPerc(rowData.costPerBag);
  };

  const handleRowClick11 = (rowData, index) => {
    setIndexD(index);
    handleItemName1(rowData.itemName);
    setQuantity1(rowData.itemQty);
    setExtractPerc(rowData.extractPercentage);
    setSizeofBagafterExtractPerc(rowData.extractedKgs);
    setCostafterextractPerc(rowData.costAfterExtraction);
    setCostPerBag(rowData.costOfBag);
    setmarkup(rowData.markup);
  };

  const handleRowClick1 = (rowData, index) => {
    setDerivedflag(1);
    setIndexD(index);
    handleItemName1(rowData.DERIVEDITEMDESCRIPTION);
    setQuantity1(rowData.DERIVEDITEMQTY);
    setExtractPerc(rowData.DERIVEDITEMPERC);
    setSizeofBagafterExtractPerc(rowData.DERIVEDKG);
    setCostafterextractPerc(rowData.DERIVEDITEMRATE);
    setCostPerBag(rowData.COSTOFSTANDARDBAG);
    setmarkup(rowData.MARKUPPERC);
  };

  const handleRowClick2 = (rowData) => {
    setSelectedRowData2(rowData);
    setSelectedChargesCost(rowData);
  };

  async function SaveHeaders() {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertHeaders`,
        {
          prodno: productionNo,
          date: date,
          username: username.split("@")[0].toUpperCase(),
          MILLNO: selmillno,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveRawMaterial() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < addedValues.length; index++) {
      const value = addedValues[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertRawMaterials`,
          {
            prodno: value.productionNo,
            rawmaterialcode: value.itemCode,
            qty: value.itemQty,
            rate: value.costPerBag,
            perc: value.percentage,
            kgs: value.sizeOfBag,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == addedValues.length && successMessage) {
      alert(successMessage);
    }
  }

  async function DeleteRawMaterial(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteRawMaterials`,
        {
          prodno: Number(selproductionNo),
          rawmaterialcode: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveDerived() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < tableData.length; index++) {
      const value = tableData[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertDerivedItems`,
          {
            prodno: productionNo,
            deriveditemcode: value.itemCode,
            qty: value.itemQty,
            rate: value.costAfterExtraction,
            perc: value.extractPercentage,
            kgs: value.extractedKgs,
            otherchargescost: value.extractioncostOtherCharges,
            otherchargesbag: value.costOfBagOtherCharges,
            bagsize: value.bagSize,
            costofbag: value.costOfBag,
            markup: value.markup,
            sellingprice: value.sellingPriceOthercharges,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == tableData.length && successMessage) {
      alert(successMessage);
    }
  }

  async function DeleteDerived(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteDerivedItems`,
        {
          prodno: Number(selproductionNo),
          deriveditemcode: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveOtherCharges() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < otherChargesData.length; index++) {
      const value = otherChargesData[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertOtherCharges`,
          {
            prodno: productionNo,
            othercharge: value.description,
            otherchargeamount: value.charges,
            qty: value.numberOfBags,
            rate: value.totalCharges,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == otherChargesData.length && successMessage) {
      alert(successMessage);
    }
  }

  async function DeleteOtherCharges(value) {
    try {
      const response = await axios.post(
        `${BASEURL}/DeleteOtherCharges`,
        {
          prodno: Number(selproductionNo),
          othercharge: value,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
      alert(error.response.data.message);
    }
  }

  async function SaveOtherChargesasnew() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < historicOtherCharges.length; index++) {
      const value = historicOtherCharges[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertOtherCharges`,
          {
            prodno: productionNo,
            othercharge: value.OTHERCHARGE,
            otherchargeamount: value.OTHERCHARGEAMOUNT,
            qty: value.FORQTYBAGS,
            rate: value.CHARGAMOUNTPERBAG,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == historicOtherCharges.length && successMessage) {
      alert(successMessage);
    }
  }

  // Update
  async function UpdateRawMaterial(production) {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < updatedRawMaterials.length; index++) {
      const value = updatedRawMaterials[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertRawMaterials`,
          {
            prodno: production,
            rawmaterialcode: value.RAWMTERIALCODE,
            qty: value.RAWMATERIALQTY,
            rate: value.RAWMATERIALRATE,
            perc: value.RAWMATRERIALPERCENTAGE,
            kgs: value.KBBASEDONPERC,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == updatedRawMaterials.length && successMessage) {
      alert(successMessage);
    }
  }

  async function UpdateDerived(production) {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    for (let index = 0; index < updatedDerived.length; index++) {
      const value = updatedDerived[index];

      try {
        const response = await axios.post(
          `${BASEURL}/InsertDerivedItems`,
          {
            prodno: production,
            deriveditemcode: value.DERIVEDITEMCODE,
            qty: value.DERIVEDITEMQTY,
            rate: value.DERIVEDITEMRATE,
            perc: value.DERIVEDITEMPERC,
            kgs: value.DERIVEDKG,
            otherchargescost: value.DERIVEDTOTALKG,
            otherchargesbag: value.COSTPERSTANDARDKGDERIVED,
            bagsize: value.STANDARDKGBAG,
            costofbag: value.COSTOFSTANDARDBAG,
            markup: value.MARKUPPERC,
            sellingprice: value.DERIVEDSELLINGPRICE,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setisloading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == updatedDerived.length && successMessage) {
      alert(successMessage);
    }
  }

  const getHistoricRaw = (prod) => {
    axios
      .post(
        `${BASEURL}/GetRaw`,
        {
          ProductionNo: prod,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((resonse) => {
        setSelmillno(resonse?.data?.[0]?.MILL);
        setHistoricRaw(resonse.data);
        setAddedValues(resonse.data);
        setShowTable(true);
      })
      .catch((error) => {
        console.error("Error fetching chargescost:", error);
      });
  };

  const getHistoricDerived = (prod) => {
    axios
      .post(
        `${BASEURL}/GetDerive`,
        {
          ProductionNo: prod,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((resonse) => {
        sethistoricDerived(resonse.data);
        setShowTable1(true);
      })
      .catch((error) => {
        console.error("Error fetching chargescost:", error);
      });
  };

  const getHistoricOtherCharges = (prod) => {
    axios
      .post(
        `${BASEURL}/GetOther`,
        {
          ProductionNo: prod,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((resonse) => {
        sethistoricOtherCharges(resonse.data);
        setShowTable2(true);
      })
      .catch((error) => {
        console.error("Error fetching chargescost:", error);
      });
  };

  const getListOfProductionNumber = () => {
    axios
      .get(`${BASEURL}/ListOfProductionNumbers`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setlistofproductionno(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Production No:", error);
      });
  };

  const getProductionNo = () => {
    axios
      .get(`${BASEURL}/ProductionNumber`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setProductionNo(response.data[0].LATESTPRODNUMBER);
      })
      .catch((error) => {
        console.error("Error fetching Production No:", error);
      });
  };

  const getproduct = () => {
    axios
      .get(`${BASEURL}/RawMaterials`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response?.data);
        setProduct(response.data);
      })
      .catch((error) => {
        console.error("Error fetching item code:", error);
      });
  };

  const getotherchargescost = () => {
    axios
      .get(`${BASEURL}/OtherCharges`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resonse) => {
        setChargesCost(resonse.data);
      })
      .catch((error) => {
        console.error("Error fetching chargescost:", error);
      });
  };

  const getMillNoDetails = () => {
    axios
      .get(`${BASEURL}/FetchMillDetails`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resonse) => {
        setMillNoList(resonse?.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Mill No's:", error);
      });
  };

  const getDerivedItems = () => {
    axios
      .get(`${BASEURL}/LoadItems`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resonse) => {
        setisloading(false);
        setDerivedItem(resonse.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching chargescost:", error);
      });
  };

  // Normal Raw Material
  const handleButtonClick = () => {
    if (!selItemCode || !selitemname) {
      alert("Please select Raw Material first");
    } else if (!perc) {
      alert("Please enter a percentage value.");
    } else {
      if (rawflag === 1) {
        let updatedvalue = [...addedValues];
        updatedvalue[index] = {
          productionNo: productionNo,
          itemCode: selItemCode,
          itemName: selitemname,
          itemQty: quantity,
          costPerBag: costafterperc,
          totalCost: quantity * costafterperc,
          sizeOfBag: sizeofBagafterperc,
          percentage: perc,
        };
        setAddedValues(updatedvalue);
        setShowTable(true);
        clearallinputs();
        setRawflag(0);
      } else {
        const newValue = {
          productionNo: productionNo,
          itemCode: selItemCode,
          itemName: selitemname,
          itemQty: quantity,
          costPerBag: costafterperc,
          totalCost: quantity * costafterperc,
          sizeOfBag: sizeofBagafterperc,
          percentage: perc,
        };

        const isDuplicateRow = addedValues.some((charge) => {
          return (
            charge.itemName === newValue.itemName &&
            charge.itemCode === newValue.itemCode
          );
        });

        if (isDuplicateRow) {
          alert("This Raw Material has been added previusly.");
        } else {
          setAddedValues([...addedValues, newValue]);
        }

        setShowTable(true);
        clearallinputs();
      }
    }
  };

  // Add Raw Material after selectedProduction No
  const addnewRawMaterial = () => {
    if (!selItemCode || !selitemname) {
      alert("Please select Raw Material first");
    } else if (!perc) {
      alert("Please enter a percentage value.");
    } else {
      const newValue = {
        BLENDINGNO: selproductionNo,
        RAWMTERIALCODE: selItemCode,
        RAWMATERIALDESCRIPTION: selitemname,
        RAWMATERIALQTY: quantity,
        RAWMATERIALRATE: costafterperc,
        RAWMATERIALCOST: quantity * costafterperc,
        KBBASEDONPERC: sizeofBagafterperc,
        RAWMATRERIALPERCENTAGE: perc,
      };

      let isDuplicateRow = false;
      if (updatedRawMaterials.length > 0) {
        isDuplicateRow = updatedRawMaterials.some((charge) => {
          return (
            charge.RAWMATERIALDESCRIPTION === newValue.RAWMATERIALDESCRIPTION &&
            charge.RAWMTERIALCODE === newValue.RAWMTERIALCODE
          );
        });
      } else if (historicRaw.length > 0) {
        isDuplicateRow = historicRaw.some((charge) => {
          return (
            charge.RAWMATERIALDESCRIPTION === newValue.RAWMATERIALDESCRIPTION &&
            charge.RAWMTERIALCODE === newValue.RAWMTERIALCODE
          );
        });
      }

      if (isDuplicateRow) {
        alert("This Raw Material has been added previusly.");
      } else {
        let size = 0;
        if (updatedRawMaterials.length > 0) {
          updatedRawMaterials.forEach((item) => {
            size += parseFloat(item.KBBASEDONPERC);
          });
        } else if (historicRaw.length > 0) {
          historicRaw.forEach((item) => {
            size += parseFloat(item.KBBASEDONPERC);
          });
        }

        size = size + Number(sizeofBagafterperc);

        if (size > 90) {
          alert("you are exceeding the limit");
        } else {
          if (updatedRawMaterials.length > 0) {
            setupdatedRawMaterials([...updatedRawMaterials, newValue]);
          } else if (historicRaw.length > 0) {
            setupdatedRawMaterials([...historicRaw, newValue]);
          } else {
            setupdatedRawMaterials([...updatedRawMaterials, newValue]);
          }
        }
      }

      setShowTable(true);
      clearallinputs();
    }
  };

  // Modifying Raw Materials
  const handleButtonClickprod = () => {
    let updatedValues = [];
    if (updatedRawMaterials.length > 0) {
      updatedValues = [...updatedRawMaterials];
    } else {
      updatedValues = [...historicRaw];
    }
    updatedValues[index] = {
      BLENDINGNO: selproductionNo,
      RAWMTERIALCODE: selItemCode,
      RAWMATERIALDESCRIPTION: selitemname,
      RAWMATERIALQTY: quantity,
      RAWMATERIALRATE: costafterperc,
      RAWMATERIALCOST: quantity * costafterperc,
      KBBASEDONPERC: sizeofBagafterperc,
      RAWMATRERIALPERCENTAGE: perc,
    };

    let size = 0;
    updatedValues.forEach((item) => {
      size += parseFloat(item.KBBASEDONPERC);
    });

    if (size > 90) {
      alert("you are exceeding the limit");
    } else {
      setupdatedRawMaterials(updatedValues);
    }

    setShowTable(true);
    clearallinputs();
  };

  // Remove Normal Raw Materials
  const RemoveRawMaterials = () => {
    if (addedValues.length > 0 && tableData.length === 0) {
      const updatedValues = [...addedValues];

      if (index !== null && index >= 0 && index < updatedValues.length) {
        updatedValues.splice(index, 1);
        setIndex(null);
      } else {
        updatedValues.pop();
      }
      setAddedValues(updatedValues);
      if (updatedValues.length === 0) {
        setShowTable(false);
      }
    } else if (tableData.length > 0) {
      alert("You can not remove the raw material when there is Derived Item");
    }

    if (showTable == false) {
      alert("Please add Raw Material First");
    }
    clearallinputs();
  };

  // Remove Raw Material after selproduction no
  const RemoveRawMaterialsprod = () => {
    if (window.confirm("Are you sure you want to remove Raw Material")) {
      setRawflag(0);
      let updatedValues = [];
      if (updatedRawMaterials.length > 0) {
        updatedValues = [...updatedRawMaterials];
      } else {
        updatedValues = [...historicRaw];
      }

      if (index !== null && index >= 0 && index < updatedValues.length) {
        const isExisting = historicRaw.some((charge) => {
          return charge.RAWMTERIALCODE === updatedValues[index].RAWMTERIALCODE;
        });

        if (isExisting) {
          DeleteRawMaterial(updatedValues[index].RAWMTERIALCODE);
          updatedValues.splice(index, 1);
          setIndex(null);
        } else {
          updatedValues.splice(index, 1);
          setIndex(null);
        }
      } else {
        const isExisting = historicRaw.some((charge) => {
          return (
            charge.RAWMTERIALCODE ===
            updatedValues[updatedValues.length - 1].RAWMTERIALCODE
          );
        });
        if (isExisting) {
          DeleteRawMaterial(
            updatedValues[updatedValues.length - 1].RAWMTERIALCODE
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }

      setupdatedRawMaterials(updatedValues);

      if (updatedValues.length === 0) {
        setShowTable(false);
      }
      if (showTable === false) {
        alert("Please add Raw Material First");
      }
    }
    clearallinputs();
  };

  const clearallinputs = () => {
    setselItemCode("");
    setselItemName("");
    setQuantity("");
    setCostprice("");
    setPerc("");
    setCostAfterPerc("");
    setRawsizebag("");
    setSizeofBagAfterPerc("");
  };

  const handleButtonClick1 = () => {
    if (!totalCostPerBag) {
      alert("Please select a raw material first");
    } else if (!selitemcode1 || !selitemname1) {
      alert("Please select Derived Item first");
    } else if (!extractperc) {
      alert("Please enter extract percentage value.");
    } else if (!markup) {
      alert("Please enter Markup percentage value.");
    } else {
      if (derivedflag === 1) {
        let updatedvalue = [...tableData];
        updatedvalue[indexd] = {
          itemCode: selitemcode1,
          itemName: selitemname1,
          itemQty: quantity1,
          costAfterExtraction: costafterextractperc,
          extractedKgs: sizeofBagafterextractperc,
          extractPercentage: extractperc,
          costOfBag: costperbag,
          bagSize: derivedsizebag,
          markup: markup,
        };
        setTableData(updatedvalue);
        setShowTable1(true);
        clear();
        setDerivedflag(0);
      } else {
        const newItem = {
          itemCode: selitemcode1,
          itemName: selitemname1,
          itemQty: quantity1,
          costAfterExtraction: costafterextractperc,
          extractedKgs: sizeofBagafterextractperc,
          extractPercentage: extractperc,
          costOfBag: costperbag,
          bagSize: derivedsizebag,
          markup: markup,
        };
        const isDuplicateRow = tableData.some((charge) => {
          return (
            charge.itemName === newItem.itemName &&
            charge.itemCode === newItem.itemCode
          );
        });

        if (isDuplicateRow) {
          alert("This Derived item has been added previusly.");
        } else {
          setTableData([...tableData, newItem]);
        }

        setShowTable1(true);
        clear();
      }
    }
  };

  // Add new Derived Items
  const addnewDerivedItems = () => {
    var newTotalCost = 0;
    var extractioncostOtherCharges = 0;
    var costOfBagOtherCharges = 0;
    var sellingPriceOthercharges = 0;
    newTotalCost = totalCostPerBaggs + charge;
    setTotalCostPerBag1(newTotalCost);

    extractioncostOtherCharges =
      parseFloat(costafterextractperc) +
      charge * (parseFloat(extractperc) / 100);

    costOfBagOtherCharges =
      parseFloat(costperbag) + charge * (parseFloat(extractperc) / 100);

    sellingPriceOthercharges =
      parseFloat(costperbag) +
      charge * (parseFloat(extractperc) / 100) +
      (parseFloat(costperbag) +
        (charge * (parseFloat(extractperc) / 100) * parseFloat(markup)) / 100);

    if (!selitemcode1 || !selitemname1) {
      alert("Please select Derived Item first");
    } else if (!extractperc) {
      alert("Please enter extract percentage value.");
    } else if (!markup) {
      alert("Please enter Markup percentage value.");
    } else {
      const newItem = {
        DERIVEDITEMCODE: selitemcode1,
        DERIVEDITEMDESCRIPTION: selitemname1,
        DERIVEDITEMQTY: quantity1,
        DERIVEDITEMRATE: costafterextractperc,
        DERIVEDKG: sizeofBagafterextractperc,
        DERIVEDITEMPERC: extractperc,
        COSTOFSTANDARDBAG: costperbag,
        STANDARDKGBAG: derivedsizebag,
        DERIVEDTOTALKG: extractioncostOtherCharges,
        COSTPERSTANDARDKGDERIVED: costOfBagOtherCharges,
        MARKUPPERC: markup,
        DERIVEDSELLINGPRICE: sellingPriceOthercharges,
      };

      let isDuplicateRow = false;

      if (updatedDerived.length > 0) {
        isDuplicateRow = updatedDerived.some((charge) => {
          return (
            charge.DERIVEDITEMDESCRIPTION === newItem.DERIVEDITEMDESCRIPTION &&
            charge.DERIVEDITEMCODE === newItem.DERIVEDITEMCODE
          );
        });
      } else {
        isDuplicateRow = historicDerived.some((charge) => {
          return (
            charge.DERIVEDITEMDESCRIPTION === newItem.DERIVEDITEMDESCRIPTION &&
            charge.DERIVEDITEMCODE === newItem.DERIVEDITEMCODE
          );
        });
      }

      if (isDuplicateRow) {
        alert("This Derived item has been added previusly.");
      } else {
        let size = 0;
        if (updatedDerived.length > 0) {
          updatedDerived.forEach((item) => {
            size += parseFloat(item.DERIVEDKG);
          });
        } else if (historicDerived.length > 0) {
          historicDerived.forEach((item) => {
            size += parseFloat(item.DERIVEDKG);
          });
        }

        size = size + Number(sizeofBagafterextractperc);
        if (totalSizeOfBaggs > 0) {
          console.log(totalSizeOfBaggs);
          if (size > totalSizeOfBaggs) {
            alert("you are exceeding the limit");
          } else {
            if (updatedDerived.length > 0) {
              setUpdatedDerived([...updatedDerived, newItem]);
            } else if (historicDerived.length > 0) {
              setUpdatedDerived([...historicDerived, newItem]);
            } else {
              setUpdatedDerived([...updatedDerived, newItem]);
            }
          }
        } else if (totalSizeOfBagg > 0) {
          console.log(totalSizeOfBagg);
          if (size > totalSizeOfBagg) {
            alert("you are exceeding the limit");
          } else {
            if (updatedDerived.length > 0) {
              setUpdatedDerived([...updatedDerived, newItem]);
            } else if (historicDerived.length > 0) {
              setUpdatedDerived([...historicDerived, newItem]);
            } else {
              setUpdatedDerived([...updatedDerived, newItem]);
            }
          }
        }
      }

      setShowTable1(true);
      clear();
    }
  };

  // Modifying Derived Items
  const handleButtonClick1prod = () => {
    if (!selitemcode1 || !selitemname1) {
      alert("Please select Derived Item first");
    } else if (!extractperc) {
      alert("Please enter extract percentage value.");
    } else if (!markup) {
      alert("Please enter Markup percentage value.");
    } else {
      var newTotalCost = 0;
      var extractioncostOtherCharges = 0;
      var costOfBagOtherCharges = 0;
      var sellingPriceOthercharges = 0;
      let updatedValues = [];
      newTotalCost = totalCostPerBaggs + charge;
      setTotalCostPerBag1(newTotalCost);

      extractioncostOtherCharges =
        parseFloat(costafterextractperc) +
        charge * (parseFloat(extractperc) / 100);

      costOfBagOtherCharges =
        parseFloat(costperbag) + charge * (parseFloat(extractperc) / 100);

      sellingPriceOthercharges =
        parseFloat(costperbag) +
        charge * (parseFloat(extractperc) / 100) +
        (parseFloat(costperbag) +
          (charge * (parseFloat(extractperc) / 100) * parseFloat(markup)) /
            100);

      if (updatedDerived.length > 0) {
        updatedValues = [...updatedDerived];
      } else {
        updatedValues = [...historicDerived];
      }
      updatedValues[indexd] = {
        DERIVEDITEMCODE: selitemcode1,
        DERIVEDITEMDESCRIPTION: selitemname1,
        DERIVEDITEMQTY: quantity1,
        DERIVEDITEMRATE: costafterextractperc,
        DERIVEDKG: sizeofBagafterextractperc,
        DERIVEDITEMPERC: extractperc,
        COSTOFSTANDARDBAG: costperbag,
        STANDARDKGBAG: derivedsizebag,
        DERIVEDTOTALKG: extractioncostOtherCharges,
        COSTPERSTANDARDKGDERIVED: costOfBagOtherCharges,
        MARKUPPERC: markup,
        DERIVEDSELLINGPRICE: sellingPriceOthercharges,
      };

      let size = 0;
      updatedValues.forEach((item) => {
        size += parseFloat(item.DERIVEDKG);
      });

      if (totalSizeOfBaggs > 0) {
        if (size > totalSizeOfBaggs) {
          alert("you are exceeding the limit");
        } else {
          setUpdatedDerived(updatedValues);
        }
      } else if (totalSizeOfBagg > 0) {
        if (size > totalSizeOfBagg) {
          alert("you are exceeding the limit");
        } else {
          setUpdatedDerived(updatedValues);
        }
      }

      setShowTable1(true);
      clear();
    }
  };

  const RemoveDeriveditems = () => {
    if (tableData.length > 0 && otherChargesData.length === 0) {
      const updatedValues = [...tableData];

      if (indexd !== null && indexd >= 0 && indexd < updatedValues.length) {
        updatedValues.splice(indexd, 1);
        setIndexD(null);
      } else {
        updatedValues.pop();
      }

      setTableData(updatedValues);
      if (updatedValues.length === 0) {
        setShowTable1(false);
      }
    } else if (otherChargesData.length > 0) {
      alert(
        "You can not remove the Derived Item when there is OtherCharges Included"
      );
    }

    if (showTable1 == false) {
      alert("Please add Derived Item First");
    }
    clear();
  };

  const RemoveDeriveditemsprod = () => {
    if (window.confirm("Are you sure you want to remove Derived Items")) {
      setDerivedflag(0);
      let updatedValues = [];
      if (updatedDerived.length > 0) {
        updatedValues = [...updatedDerived];
      } else {
        updatedValues = [...historicDerived];
      }

      if (indexd !== null && indexd >= 0 && indexd < updatedValues.length) {
        const isExisting = historicDerived.some((charge) => {
          return (
            charge.DERIVEDITEMCODE === updatedValues[indexd].DERIVEDITEMCODE
          );
        });

        if (isExisting) {
          DeleteDerived(updatedValues[indexd].DERIVEDITEMCODE);
          updatedValues.splice(indexd, 1);
          setIndexD(null);
        } else {
          updatedValues.splice(indexd, 1);
          setIndexD(null);
        }
      } else {
        const isExisting = historicDerived.some((charge) => {
          return (
            charge.DERIVEDITEMCODE ===
            updatedValues[updatedValues.length - 1].DERIVEDITEMCODE
          );
        });
        if (isExisting) {
          DeleteDerived(
            updatedValues[updatedValues.length - 1].DERIVEDITEMCODE
          );
          updatedValues.pop();
        } else {
          updatedValues.pop();
        }
      }

      setUpdatedDerived(updatedValues);

      if (updatedValues.length === 0) {
        setShowTable1(false);
      }
      if (showTable1 == false) {
        alert("Please add Derived Item First");
      }
    }
    clear();
  };

  const clear = () => {
    setselItemCode1("");
    setselItemName1("");
    setQuantity1("");
    setCostafterextractPerc("");
    setSizeofBagafterExtractPerc("");
    setExtractPerc("");
    setCostPerBag("");
    setDerivedSizeBag("");
    setmarkup("");
  };

  const handleButtonClick2 = () => {
    if (
      tableData.length === 0 &&
      updatedDerived.length === 0 &&
      historicDerived.length === 0
    ) {
      alert("Please select Derived Item first");
    } else if (!selectedChargesCost) {
      alert("Please select Other charges first");
    } else if (!numberOfBags) {
      alert("Please enter number of bags value.");
    } else {
      const newCharge = {
        description: selectedChargesCost,
        charges: otherChargesCost,
        numberOfBags: numberOfBags,
        totalCharges: otherChargesCost * numberOfBags,
      };

      const isDuplicateRow = otherChargesData.some((charge) => {
        return (
          charge.description === newCharge.description &&
          charge.charges === newCharge.charges
        );
      });

      if (isDuplicateRow) {
        alert("This charges has been added previusly.");
      } else {
        setOtherChargesData([...otherChargesData, newCharge]);
      }
      setShowTable2(true);

      var newTotalCost = 0;
      if (totalCostPerBag1) {
        newTotalCost = totalCostPerBag1 + otherChargesCost;
        setTotalCostPerBag1(newTotalCost);
      } else {
        newTotalCost = totalCostPerBag + otherChargesCost;
        setTotalCostPerBag1(newTotalCost);
      }
      const updatedTableData = tableData.map((item) => ({
        ...item,
        extractioncostOtherCharges:
          parseFloat(newTotalCost) * (parseFloat(item.extractPercentage) / 100),
        costOfBagOtherCharges:
          parseFloat(item.costOfBag) +
          parseFloat(parseFloat(newTotalCost) - parseFloat(totalCostPerBag)) *
            (parseFloat(item.extractPercentage) / 100),

        sellingPriceOthercharges:
          parseFloat(item.costOfBag) +
          parseFloat(parseFloat(newTotalCost) - parseFloat(totalCostPerBag)) *
            (parseFloat(item.extractPercentage) / 100) +
          ((parseFloat(item.costOfBag) +
            parseFloat(parseFloat(newTotalCost) - parseFloat(totalCostPerBag)) *
              (parseFloat(item.extractPercentage) / 100)) *
            parseFloat(item.markup)) /
            100,
      }));
      setTableData(updatedTableData);
      clear1();
    }
  };

  const RemoveOtherCharges = () => {
    if (otherChargesData.length > 0) {
      const updatedValues = [...otherChargesData];
      const removedRow = updatedValues.pop();
      setOtherChargesData(updatedValues);

      const updatedTableData = tableData.map((item) => ({
        ...item,
        extractioncostOtherCharges:
          updatedValues.length === 0
            ? ""
            : parseFloat(item.extractioncostOtherCharges) -
              removedRow.charges * (parseFloat(item.extractPercentage) / 100),
        costOfBagOtherCharges:
          updatedValues.length === 0
            ? ""
            : parseFloat(item.costOfBagOtherCharges) -
              removedRow.charges * (parseFloat(item.extractPercentage) / 100),
        sellingPriceOthercharges:
          updatedValues.length === 0
            ? ""
            : parseFloat(item.sellingPriceOthercharges) -
              removedRow.charges * (parseFloat(item.markup) / 100),
      }));

      setTableData(updatedTableData);

      setTotalCostPerBag1(totalCostPerBag1 - removedRow.charges);

      if (updatedValues.length === 0) {
        setShowTable2(false);
      }
    } else {
      alert("Please add Other charges First");
    }
  };

  const clear1 = () => {
    setSelectedChargesCost("");
    setOtherChargesCost("");
    setNumberOfBags("");
  };

  const handlePercantage = (e) => {
    const numericValue = parseFloat(rawsizebag.replace(/[^0-9.]/g, ""));
    const cost = (costprice * (e / 100)).toFixed(2);
    const size = parseFloat(numericValue * (e / 100)).toFixed(2);
    setCostAfterPerc(cost);
    setSizeofBagAfterPerc(size);
  };

  const handleExtractedPerc = (e) => {
    const numericValue1 = parseFloat(derivedsizebag.replace(/[^0-9.]/g, ""));
    let cost = 0;
    let size = 0;
    if (totalCostPerBaggs) {
      cost = totalCostPerBaggs;
    } else {
      cost = totalCostPerBagg;
    }
    if (totalSizeOfBaggs) {
      size = totalSizeOfBaggs;
    } else {
      size = totalSizeOfBagg;
    }
    const cost1 = ((cost ? cost : totalCostPerBag) * (e / 100)).toFixed(2);
    const size1 = parseFloat(
      (size ? size : totalSizeOfBag) * (e / 100)
    ).toFixed(2);
    const costperBag1 = parseFloat(numericValue1 * (cost1 / size1)).toFixed(2);
    setCostafterextractPerc(cost1);
    setSizeofBagafterExtractPerc(size1);
    setCostPerBag(costperBag1);
  };

  const handleExtractedKgs = (e) => {
    const numericValue1 = parseFloat(derivedsizebag.replace(/[^0-9.]/g, ""));
    let cost = 0;
    let size = 0;
    if (totalCostPerBaggs) {
      cost = totalCostPerBaggs;
    } else {
      cost = totalCostPerBagg;
    }
    if (totalSizeOfBaggs) {
      size = totalSizeOfBaggs;
    } else {
      size = totalSizeOfBagg;
    }
    setExtractPerc(
      parseFloat((Number(e) / (size ? size : totalSizeOfBag)) * 100).toFixed(2)
    );
    const cost1 = (
      (cost ? cost : totalCostPerBag) *
      (((Number(e) / (size ? size : totalSizeOfBag)) * 100) / 100)
    ).toFixed(2);
    const size1 = parseFloat(
      (size ? size : totalSizeOfBag) *
        (((Number(e) / (size ? size : totalSizeOfBag)) * 100) / 100)
    ).toFixed(2);
    const costperBag1 = parseFloat(numericValue1 * (cost1 / size1)).toFixed(2);
    setCostafterextractPerc(cost1);
    setCostPerBag(costperBag1);
  };

  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj.ITEMDESCRIPTION === selectedValue
    );
    if (selectedValue === "") {
      setselItemName("");
      setselItemCode("");
      setCostprice("");
      setQuantity("");
    } else if (selectedOption === "") {
      setselItemCode("");
      setselItemName("");
      setCostprice("");
      setQuantity("");
    } else {
      setselItemName(selectedValue);
      setselItemCode(selectedOption.ITEMCODE);
      setRawsizebag(selectedOption.SPECIFICATIONS);
      setCostprice(selectedOption.COSTPRICE.toFixed(2));
      setQuantity(1);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = product.find(
      (obj) => obj.ITEMCODE === selectedValue
    );
    if (selectedValue === "") {
      setselItemName("");
      setselItemCode("");
      setCostprice("");
      setQuantity("");
    } else if (selectedOption === "") {
      setselItemName("");
      setselItemCode("");
      setCostprice("");
      setQuantity("");
    } else {
      setselItemCode(selectedValue);
      setselItemName(selectedOption.ITEMDESCRIPTION);
      setRawsizebag(selectedOption.SPECIFICATIONS);
      setCostprice(selectedOption.COSTPRICE.toFixed(2));
      setQuantity(1);
    }
  };
  const handleItemName1 = (e) => {
    const selectedValue = e;
    const selectedOption = deriveditem.find(
      (obj) => obj.ITEMDESCRIPTION === selectedValue
    );
    if (selectedValue === "") {
      setselItemName1("");
      setselItemCode1("");
      setDerivedSizeBag("");
      setQuantity1("");
    } else if (selectedOption === "") {
      setselItemCode1("");
      setselItemName1("");
      setDerivedSizeBag("");
      setQuantity1("");
    } else {
      setselItemName1(selectedValue);
      setselItemCode1(selectedOption.ITEMCODE);
      setDerivedSizeBag(selectedOption.SPECIFICATIONS);
      setQuantity1(1);
    }
  };

  const handleItemCode1 = (e) => {
    const selectedValue = e;
    const selectedOption = deriveditem.find(
      (obj) => obj.ITEMCODE === selectedValue
    );
    if (selectedValue === "") {
      setselItemName1("");
      setselItemCode1("");
      setDerivedSizeBag("");
      setQuantity1("");
    } else if (selectedOption === "") {
      setselItemName1("");
      setselItemCode1("");
      setDerivedSizeBag("");
      setQuantity1("");
    } else {
      setselItemCode1(selectedValue);
      setselItemName1(selectedOption.ITEMDESCRIPTION);
      setDerivedSizeBag(selectedOption.SPECIFICATIONS);
      setQuantity1(1);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedChargesCost(selectedOption.value);
    const selectedCharges = chargescost.find(
      (item) => item.NONSTOCKITEM === selectedOption.value
    );

    if (selectedCharges) {
      setOtherChargesCost(selectedCharges.CHARGE);
    } else {
      setOtherChargesCost("");
    }
  };

  useEffect(() => {
    if (productionReport !== "" && deriveditem.length > 0) {
      setHist(true);
      setselProductionNo(productionReport);
      getHistoricRaw(productionReport);
      getHistoricDerived(productionReport);
      getHistoricOtherCharges(productionReport);
      dispatch(productionno(""));
    }
  }, [productionReport, deriveditem]);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AllNav />

      <h1 style={{ textAlign: "center" }}>Production Module</h1>

      <div className="production__for__Input">
        <div>
          <div className="header_import">
            <div className="import">
              <p>RAW MATERIALS</p>
            </div>
            <div className="flex">
              <button
                className="Save_import"
                onClick={() => {
                  if (
                    addedValues.length > 0 ||
                    tableData.length > 0 ||
                    updatedRawMaterials.length > 0 ||
                    updatedDerived.length > 0
                  ) {
                    if (window.confirm("Are you sure you don't want to Save")) {
                      window.location.reload();
                    }
                  } else {
                    window.location.reload();
                  }
                }}
              >
                New
              </button>
              <button
                className="Save_import"
                onClick={async () => {
                  if (
                    selmillno === "" ||
                    selmillno === null ||
                    selmillno === undefined
                  ) {
                    alert("Kindly select the Mill No");
                  } else {
                    // if (otherChargesData.length > 0) {
                    await SaveHeaders();
                    await SaveRawMaterial();
                    await SaveDerived();
                    await SaveOtherCharges();
                    // alert("Saved Succesfully");
                    window.location.reload();
                  }
                  // } else {
                  //   alert("Kindly Add all the fields to save the data");
                  // }
                }}
              >
                Save
              </button>
              <button
                className="Save_import"
                onClick={async () => {
                  await UpdateRawMaterial(selproductionNo);
                  await UpdateDerived(selproductionNo);
                  // alert("Updated Succesfully");
                  window.location.reload();
                }}
              >
                Update
              </button>
            </div>
          </div>

          <div className="Raw_materials">
            <div className="historical_prod_no">
              <div className="hist_prod">
                <p>Historical Production No</p>
                <Select
                  className="pro_select"
                  onChange={(selectedOption) => {
                    if (selectedOption.value == "") {
                      alert(
                        "Kindly choose proper Historical Production Number"
                      );
                    } else {
                      setHist(true);
                      setselProductionNo(selectedOption.value);
                      getHistoricRaw(selectedOption.value);
                      getHistoricDerived(selectedOption.value);
                      getHistoricOtherCharges(selectedOption.value);
                    }
                  }}
                  value={
                    selproductionNo
                      ? { label: selproductionNo, value: selproductionNo }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...listofproductionno.map((item) => ({
                      value: item.BLENDINGNO,
                      label: item.BLENDINGNO,
                    })),
                  ]}
                />
              </div>
              <div className="raw_date">
                <div className="date">
                  <p>Date</p>
                </div>
                <div>
                  <input
                    className="input_date"
                    type="Date"
                    value={date.split("T")[0]}
                    onChange={(e) => {
                      if (e.target.value > new Date().toISOString()) {
                        alert("Date Can not be a future date");
                      } else {
                        setDate(e.target.value);
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>

            <div className="first_line">
              <div className="Production_no">
                <p className="prod_no">Production No</p>
                <input
                  className="input_qty_1"
                  type="text"
                  value={hist ? "" : productionNo}
                  readOnly={true}
                ></input>
              </div>
              <div className="name_1">
                <p>Raw Material Name</p>

                <Select
                  className="select_name"
                  onChange={(selectedOption) => {
                    if (selectedOption.value == "") {
                      alert("Kindly choose Proper Raw Material Name");
                    } else {
                      handleItemName(selectedOption.value);
                    }
                  }}
                  value={
                    selitemname
                      ? { label: selitemname, value: selitemname }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...product.map((item) => ({
                      value: item.ITEMDESCRIPTION,
                      label: item.ITEMDESCRIPTION,
                    })),
                  ]}
                />
              </div>
              <div className="code">
                <p>Raw Material Code</p>

                <Select
                  className="select_code"
                  onChange={(selectedOption) => {
                    if (selectedOption.value == "") {
                      alert("Kindly choose Proper Raw Material Code");
                    } else {
                      handleItemCode(selectedOption.value);
                    }
                  }}
                  value={
                    selItemCode
                      ? { label: selItemCode, value: selItemCode }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...product.map((item) => ({
                      value: item.ITEMCODE,
                      label: item.ITEMCODE,
                    })),
                  ]}
                />
              </div>

              <div className="cost_after_perc">
                <p>Mill No</p>
                <Select
                  className="select_code"
                  onChange={(e) => {
                    if (e.value === "") {
                      alert("Kindly Select Proper Mill No");
                    } else {
                      setSelmillno(e.value);
                    }
                  }}
                  value={
                    selmillno
                      ? { label: selmillno, value: selmillno }
                      : { label: "", value: "" }
                  }
                  //
                  options={[
                    { value: "", label: "" },
                    ...millnolist.map((item) => ({
                      value: item.MILLNO,
                      label: item.MILLNO,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="second_line">
              <div className="size_of_Bag">
                <p>Size of Bag</p>
                <input
                  className="input_qty_1"
                  type="text"
                  value={rawsizebag}
                  readOnly={true}
                ></input>
              </div>
              <div className="qty_1">
                <p>Quantity(90 kg Bag)</p>
                <input
                  className="input_qty_1"
                  type="text"
                  readOnly={true}
                  value={
                    quantity
                      ? quantity.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                ></input>
              </div>
              <div className="cost_of_90">
                <p>Cost of(one 90 kg Bag)</p>
                <input
                  className="input_cost_of_90"
                  type="text"
                  value={
                    costprice
                      ? costprice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly={true}
                ></input>
              </div>
            </div>
            <div className="third_line">
              <div className="perc">
                <p>Percentage %</p>
                <input
                  className="input_perc"
                  type="number"
                  value={perc}
                  onChange={(e) => {
                    if (!selItemCode || !selitemname) {
                      alert("Please select Raw Material first");
                    } else if (!rawsizebag) {
                      alert("Size Of Bag is Empty");
                    } else {
                      setPerc(e.target.value);
                      handlePercantage(e.target.value);
                    }
                  }}
                ></input>
              </div>
              <div className="size_of_bag">
                <p>Size of Bag</p>
                <input
                  className="input_qty_1"
                  type="text"
                  value={sizeofBagafterperc}
                ></input>
              </div>
              <div className="cost_after_perc">
                <p>Cost after Percentage( 90 kg Bag)</p>
                <input
                  className="input_cost_after_perc"
                  type="text"
                  value={
                    costafterperc
                      ? costafterperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                ></input>
              </div>
            </div>
            <div className="btn_2">
              <button
                className="Add"
                onClick={() => {
                  if (selproductionNo) {
                    if (rawflag === 1) {
                      handleButtonClickprod();
                      setRawflag(0);
                    } else {
                      addnewRawMaterial();
                    }
                  } else {
                    handleButtonClick();
                  }
                }}
              >
                Add
              </button>
              <button
                className="Remove"
                onClick={() => {
                  if (selproductionNo) {
                    RemoveRawMaterialsprod();
                  } else {
                    RemoveRawMaterials();
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>
          {showTable && (
            <div className="visible">
              <table className="table_1">
                <thead>
                  <tr className="table-header">
                    <td style={{ textAlign: "right" }}>ITEM CODE</td>
                    <td>ITEM NAME</td>
                    <td style={{ textAlign: "right" }}>NO.OF BAGS</td>
                    <td style={{ textAlign: "right" }}>COST PER 1 BAG</td>
                    <td style={{ textAlign: "right" }}>TOTAL COST</td>
                    <td style={{ textAlign: "right" }}>SIZE OF BAG</td>
                    <td style={{ textAlign: "right" }}>PERCENTAGE %</td>
                  </tr>
                </thead>
                <tbody>
                  {updatedRawMaterials.length > 0
                    ? updatedRawMaterials.map((item, index) => (
                        <tr
                          onClick={() => handleRowClick(item, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMTERIALCODE}
                          </td>
                          <td>{item.RAWMATERIALDESCRIPTION}</td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATERIALQTY}
                          </td>
                          <td style={{}}>
                            {item.RAWMATERIALRATE
                              ? item.RAWMATERIALRATE.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{}}>
                            {item.RAWMATERIALCOST
                              ? item.RAWMATERIALCOST.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.KBBASEDONPERC}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATRERIALPERCENTAGE}
                          </td>
                        </tr>
                      ))
                    : historicRaw.length > 0
                    ? historicRaw.map((item, index) => (
                        <tr
                          onClick={() => handleRowClick(item, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMTERIALCODE}
                          </td>
                          <td>{item.RAWMATERIALDESCRIPTION}</td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATERIALQTY}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATERIALRATE
                              ? item.RAWMATERIALRATE.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATERIALCOST
                              ? item.RAWMATERIALCOST.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.KBBASEDONPERC}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.RAWMATRERIALPERCENTAGE}
                          </td>
                        </tr>
                      ))
                    : addedValues.map((value, index) => (
                        <tr
                          onClick={() => handleRowClickk(value, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td style={{ textAlign: "right" }}>
                            {value.itemCode}
                          </td>
                          <td>{value.itemName}</td>
                          <td style={{ textAlign: "right" }}>
                            {value.itemQty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {value.costPerBag
                              ? value.costPerBag.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {value.totalCost
                              ? value.totalCost.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {value.sizeOfBag}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {value.percentage}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <div className="total_cost">
                <div className="total_size">
                  <p>
                    Total Size of Bag &nbsp;
                    <input
                      className="input_total_size"
                      type="text"
                      value={
                        totalSizeOfBaggs
                          ? totalSizeOfBaggs.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : totalSizeOfBagg
                          ? totalSizeOfBagg.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : totalSizeOfBag
                          ? totalSizeOfBag.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""
                      }
                    ></input>
                    &nbsp; KG
                  </p>
                </div>
                <div className="total_Bag">
                  <p>Total Cost per Bag</p>
                  <input
                    type="text"
                    className="input_total_Bag"
                    value={
                      totalCostPerBaggs
                        ? charge
                          ? (totalCostPerBaggs + charge).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : totalCostPerBaggs.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        : totalCostPerBagg
                        ? charge
                          ? (totalCostPerBagg + charge).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : totalCostPerBagg.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        : totalCostPerBag1 > 0
                        ? totalCostPerBag1.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : totalCostPerBag
                        ? totalCostPerBag.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : ""
                    }
                  ></input>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="import">
            <p>DERIVED ITEMS</p>
          </div>
          <div className="Derived_items">
            <div className="first_line">
              <div className="derived_name">
                <p>Derived Item Name</p>
                <Select
                  className="select_name"
                  onChange={(e) => {
                    if (selproductionNo) {
                      if (totalCostPerBagg || totalCostPerBaggs) {
                        if (e.value == "") {
                          alert("Kindly choose Proper Derived Item Name");
                        } else {
                          handleItemName1(e.value);
                        }
                      } else {
                        alert("Please select a raw material first");
                      }
                    } else if (!totalCostPerBag) {
                      alert("Please select a raw material first");
                    } else {
                      if (e.value == "") {
                        alert("Kindly choose Proper Derived Item Name");
                      } else {
                        handleItemName1(e.value);
                      }
                    }
                  }}
                  value={
                    selitemname1
                      ? { label: selitemname1, value: selitemname1 }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...deriveditem.map((item) => ({
                      value: item.ITEMDESCRIPTION,
                      label: item.ITEMDESCRIPTION,
                    })),
                  ]}
                />
              </div>
              <div className="derived_code">
                <p>Derived Item Code</p>
                <Select
                  className="select_code"
                  onChange={(e) => {
                    if (!totalCostPerBag) {
                      alert("Please select a raw material first");
                    } else {
                      if (e.value == "") {
                        alert("Kindly Choose Proper Item Code");
                      } else {
                        handleItemCode1(e.value);
                      }
                    }
                  }}
                  value={
                    selitemcode1
                      ? { label: selitemcode1, value: selitemcode1 }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...deriveditem.map((item) => ({
                      value: item.ITEMCODE,
                      label: item.ITEMCODE,
                    })),
                  ]}
                />
              </div>
              <div className="derived_qty">
                <p>Quantity</p>
                <input
                  className="input_qty_1 "
                  type="number"
                  value={quantity1}
                  readOnly={true}
                ></input>
              </div>
              <div className="size_of_bag1">
                <p>Size of Bag</p>
                <input
                  className="input_size_of_bag"
                  type="text"
                  value={derivedsizebag}
                  readOnly={true}
                ></input>
              </div>
            </div>
            <div className="second_line">
              <div className="Extract">
                <p>Extract %</p>
                <input
                  className="input_qty_1 "
                  type="number"
                  value={extractperc}
                  onChange={(e) => {
                    setExtractPerc(e.target.value);
                    handleExtractedPerc(e.target.value);
                  }}
                ></input>
              </div>
              <div className="cost_after">
                <p>Cost after Extraction</p>
                <input
                  className="input_qty_2 "
                  type="text"
                  value={costafterextractperc}
                ></input>
              </div>
              <div className="extracted_kg">
                <p>Extracted Kgs</p>
                <input
                  className="input_qty_2 "
                  type="number"
                  value={sizeofBagafterextractperc}
                  // readOnly={true}
                  onChange={(e) => {
                    setSizeofBagafterExtractPerc(e.target.value);
                    handleExtractedKgs(e.target.value);
                  }}
                ></input>
              </div>
              <div className="cost_per_bag" style={{ width: "272px" }}>
                <p>Cost Per Bag</p>
                <input
                  className="input_qty_3 "
                  type="number"
                  readOnly={true}
                  value={costperbag}
                ></input>
              </div>
              <div className="cost_per_bag">
                <p>Markup %</p>
                <input
                  className="input_qty_3 "
                  type="number"
                  value={markup}
                  onChange={(e) => {
                    setmarkup(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className="third_line_der">
            <div className="btn_2">
              <button
                className="Add"
                onClick={() => {
                  if (selproductionNo) {
                    if (derivedflag === 1) {
                      handleButtonClick1prod();
                      setDerivedflag(0);
                    } else {
                      addnewDerivedItems();
                    }
                  } else {
                    handleButtonClick1();
                  }
                }}
              >
                Add
              </button>
              <button
                className="Remove"
                onClick={() => {
                  if (selproductionNo) {
                    RemoveDeriveditemsprod();
                  } else {
                    RemoveDeriveditems();
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>
          {showTable1 && (
            <div className="visible">
              <table className="table_1">
                <thead>
                  <tr className="table-header">
                    <td style={{ textAlign: "right" }}>ITEM CODE</td>
                    <td>ITEM NAME</td>
                    <td style={{ textAlign: "right" }}>NO.OF BAGS</td>
                    <td style={{ textAlign: "right" }}>
                      COST AFTER EXTRACTION
                    </td>
                    <td style={{ textAlign: "right" }}>EXTRACTED(KGS)</td>
                    <td style={{ textAlign: "right" }}>EXTRACT %</td>
                    <td style={{ textAlign: "right" }}>COST OF BAG</td>
                    <td style={{ textAlign: "right" }}>BAG SIZE</td>
                    <td style={{ textAlign: "right" }}>
                      EXTRACTION COST(INCL OTHER CHARGES)
                    </td>
                    <td style={{ textAlign: "right" }}>
                      COST OF BAG(INCL OTHER CHARGES)
                    </td>
                    <td style={{ textAlign: "right" }}>MARKUP %</td>
                    <td style={{ textAlign: "right" }}>
                      SELLING PRICE(INCL OTHER CHARGES)
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {updatedDerived.length > 0
                    ? updatedDerived.map((item, index) => (
                        <tr
                          onClick={() => handleRowClick1(item, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            className="td_derived_item_code"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMCODE}
                          </td>
                          <td>{item.DERIVEDITEMDESCRIPTION}</td>
                          <td
                            className="td_derived_item_qty"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMQTY}
                          </td>
                          <td
                            className="td_derived_item_rate"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMRATE}
                          </td>
                          <td
                            className="td_derived_item_kg"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDKG}
                          </td>
                          <td
                            className="td_derived_item_perc"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMPERC}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.COSTOFSTANDARDBAG}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.STANDARDKGBAG}
                          </td>
                          <td
                            className="td_derived_item_total"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDTOTALKG
                              ? item.DERIVEDTOTALKG.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td
                            className="td_cost_per_stand"
                            style={{ textAlign: "right" }}
                          >
                            {item.COSTPERSTANDARDKGDERIVED
                              ? item.COSTPERSTANDARDKGDERIVED.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.MARKUPPERC ? item.MARKUPPERC : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.DERIVEDSELLINGPRICE
                              ? item.DERIVEDSELLINGPRICE.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                      ))
                    : historicDerived.length > 0
                    ? historicDerived.map((item, index) => (
                        <tr
                          onClick={() => handleRowClick1(item, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            className="td_derived_item_code"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMCODE}
                          </td>
                          <td>{item.DERIVEDITEMDESCRIPTION}</td>
                          <td
                            className="td_derived_item_qty"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMQTY}
                          </td>
                          <td
                            className="td_derived_item_rate"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMRATE}
                          </td>
                          <td
                            className="td_derived_item_kg "
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDKG}
                          </td>
                          <td
                            className="td_derived_item_perc"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDITEMPERC}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.COSTOFSTANDARDBAG}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.STANDARDKGBAG}
                          </td>
                          <td
                            className="td_derived_item_total"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDTOTALKG
                              ? item.DERIVEDTOTALKG.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ""}
                          </td>
                          <td
                            className="td_cost_per_stand"
                            style={{ textAlign: "right" }}
                          >
                            {item.COSTPERSTANDARDKGDERIVED
                              ? item.COSTPERSTANDARDKGDERIVED.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                          <td
                            className="td_derived_item_kg "
                            style={{ textAlign: "right" }}
                          >
                            {item.MARKUPPERC}
                          </td>
                          <td
                            className="td_cost_per_stand"
                            style={{ textAlign: "right" }}
                          >
                            {item.DERIVEDSELLINGPRICE
                              ? item.DERIVEDSELLINGPRICE.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                      ))
                    : tableData.map((item, index) => (
                        <tr
                          onClick={() => handleRowClick11(item, index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            className="td_derived_item_code"
                            style={{ textAlign: "right" }}
                          >
                            {item.itemCode}
                          </td>
                          <td>{item.itemName}</td>
                          <td
                            className="td_derived_item_qty"
                            style={{ textAlign: "right" }}
                          >
                            {item.itemQty}
                          </td>
                          <td
                            className="td_derived_item_rate"
                            style={{ textAlign: "right" }}
                          >
                            {item.costAfterExtraction}
                          </td>
                          <td
                            className="td_derived_item_kg "
                            style={{ textAlign: "right" }}
                          >
                            {item.extractedKgs}
                          </td>
                          <td
                            className="td_derived_item_perc"
                            style={{ textAlign: "right" }}
                          >
                            {item.extractPercentage}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {item.costOfBag}
                          </td>
                          <td style={{ textAlign: "right" }}>{item.bagSize}</td>
                          <td
                            className="td_derived_item_total"
                            style={{ textAlign: "right" }}
                          >
                            {item.extractioncostOtherCharges
                              ? item.extractioncostOtherCharges.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                          <td
                            className="td_cost_per_stand"
                            style={{ textAlign: "right" }}
                          >
                            {item.costOfBagOtherCharges
                              ? item.costOfBagOtherCharges.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>{item.markup}</td>
                          <td
                            className="td_cost_per_stand"
                            style={{ textAlign: "right" }}
                          >
                            {item.sellingPriceOthercharges
                              ? item.sellingPriceOthercharges.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <div className="total_cost">
                <div className="total_size">
                  <p>
                    Total Size of Bag &nbsp;
                    <input
                      type="text"
                      className="input_total_size"
                      value={
                        totalderrkgss
                          ? totalderrkgss.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : totalderrkgs
                          ? totalderrkgs.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : totalExtractedKg
                          ? totalExtractedKg.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""
                      }
                    ></input>
                    &nbsp; KG
                  </p>
                </div>
                <div className="total_Bag">
                  <p>Total Derived Cost</p>
                  <input
                    type="text"
                    className="input_total_Bag"
                    value={
                      totalderrs
                        ? totalderrs.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : totalderr
                        ? totalderr.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : totalDerivedCost
                        ? totalDerivedCost.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : ""
                    }
                  ></input>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="import">
          <p>OTHER CHARGES</p>
        </div>
        <div className="other_charges">
          <div className="first_line">
            <div className="other_per_90kg">
              <p>Other Charges per 90kg </p>
              <Select
                className="select_name"
                onChange={(selectedOption) => {
                  if (
                    tableData.length === 0 &&
                    updatedDerived.length === 0 &&
                    historicDerived.length === 0
                  ) {
                    alert("Please select Derived Items first");
                  } else {
                    if (selectedOption.value == "") {
                      alert("Kindly choose Proper Other charges");
                    } else {
                      handleOptionChange(selectedOption);
                    }
                  }
                }}
                value={
                  selectedRowData2
                    ? {
                        label: selectedRowData2.OTHERCHARGE,
                        value: selectedRowData2.OTHERCHARGE,
                      }
                    : selectedChargesCost
                    ? { label: selectedChargesCost, value: selectedChargesCost }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...chargescost.map((item) => ({
                    value: item.NONSTOCKITEM,
                    label: item.NONSTOCKITEM,
                  })),
                ]}
              />
            </div>
            <div className="other_per_90kg_item">
              <p>Other Charges cost</p>
              <input
                className="input_qty_2 "
                type="number"
                value={
                  selectedRowData2
                    ? selectedRowData2.OTHERCHARGEAMOUNT
                    : otherChargesCost
                }
              ></input>
            </div>
            <div className="No_of_bags">
              <p>Number of Bags</p>
              <input
                className="input_qty_2"
                type="number"
                defaultValue={
                  selectedRowData2 ? selectedRowData2.FORQTYBAGS : numberOfBags
                }
                onChange={(e) => {
                  if (
                    tableData.length === 0 &&
                    updatedDerived.length === 0 &&
                    historicDerived.length === 0
                  ) {
                    alert("Please select Derived Item first");
                    setNumberOfBags("");
                  } else if (!selectedChargesCost) {
                    alert("Please select Other charges first");
                    setNumberOfBags("");
                  } else {
                    setNumberOfBags(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="btn_3">
            <button
              className="Add"
              onClick={() => {
                if (selproductionNo) {
                  alert("You can not modify Historical Other Charges");
                } else {
                  handleButtonClick2();
                }
              }}
            >
              Add
            </button>
            <button
              className="Remove"
              onClick={() => {
                if (selproductionNo) {
                  alert("You can not modify Historical Other Charges");
                } else {
                  RemoveOtherCharges();
                }
              }}
            >
              Remove
            </button>
          </div>
        </div>
        {showTable2 && (
          <div className="visible">
            <table className="table_1">
              <thead>
                <tr className="table-header">
                  <td>DESCRIPTION</td>
                  <td style={{ textAlign: "right" }}>CHARGES</td>
                  <td style={{ textAlign: "right" }}> NUMBER OF BAGS</td>
                  <td style={{ textAlign: "right" }}>TOTAL CHARGES</td>
                </tr>
              </thead>
              <tbody>
                {historicOtherCharges.length > 0
                  ? historicOtherCharges.map((item) => (
                      <tr
                        onClick={() => handleRowClick2(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{item.OTHERCHARGE}</td>
                        <td style={{ textAlign: "right" }}>
                          {item.OTHERCHARGEAMOUNT}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {item.FORQTYBAGS}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {item.CHARGAMOUNTPERBAG
                            ? item.CHARGAMOUNTPERBAG.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : ""}
                        </td>
                      </tr>
                    ))
                  : otherChargesData.map((charge, index) => (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{charge.description}</td>
                        <td style={{ textAlign: "right" }}>{charge.charges}</td>
                        <td style={{ textAlign: "right" }}>
                          {charge.numberOfBags}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {charge.totalCharges
                            ? charge.totalCharges.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : ""}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <div className="total_cost">
              <p>Total Other Charges</p>
              <input
                type="text"
                className="input_total_Bag"
                value={
                  othercharges
                    ? othercharges.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : totalOtherCharges
                    ? totalOtherCharges.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
              ></input>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductionModule;
